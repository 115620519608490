import React from 'react'
import './textContent.scss'
import { Button } from './Button'
import SubTitle from './SubTitle'
import Title from './Title'
import Text from './Text'

const TextContent = ({ children }) => {
  return (
    <div className="text-content">
      <SubTitle />
      <Title />
      <Text />

      {children}
    </div>
  )
}

export default TextContent
