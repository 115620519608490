import React from 'react'
import './title.scss'

const COLOR = ['red', 'secondary', 'brand-primary']
export default function Title({ children, color }) {
  const checkTitleColor = COLOR.includes(color) ? color : null
  return (
    <div>
      <h1 className={`title ${checkTitleColor} font-sm font-semibold mb-3 `}>
        {children}
      </h1>
    </div>
  )
}
