import React, { useEffect } from 'react'
import MonthPricing from './MonthPricing'
import YearPricing from './YearPricing'
import { useState } from 'react'
import { Button } from 'components/ui/Button'
import Lottie from 'lottie-react'
import webAnimation from 'lotties/help.json'
const Pricing = () => {
  const [pricing, setPricing] = useState('month')

  const swichPricing = () => {
    if (pricing === 'month') {
      setPricing('year')
    } else {
      setPricing('month')
    }
  }
  return (
    <div className="transition ease-in-out delay-150">
      <div className="mt-5 container mx-auto flex  flex-col lg:items-center justify-center w-full">
        <h1 className="font-semibold text-gray-800 text-3xl md:text-4xl">
          Sizin için Doğru olanı seçin
        </h1>

        <p className="mt-2.5 lg:w-1/2 lg:text-center text-xl">
          İşletmenizi geliştirmek için ihtiyacınız olan paketlerden birini
          seçin. En uygun fiyatlarla teknik destek hizmeti satın alın.
        </p>
      </div>
      <div className="mt-5">
        <div className="container mx-auto">
          <div className="xl:w-4/5 w-11/12 flex justify-center mx-auto">
            <Button
              buttonStyle="btn--primary"
              buttonSize="btn--small"
              onClick={swichPricing}
            >
              {' '}
              {pricing === 'month' && 'AYLIK ÖDEME'}{' '}
              {pricing === 'year' && 'YILLIK ÖDEME'}{' '}
            </Button>
          </div>
        </div>
      </div>
      {pricing === 'month' && <MonthPricing />}

      {pricing === 'year' && <YearPricing />}
      <div className="container mx-auto flex items-center justify-center w-full">
        {' '}
        <Lottie
          animationData={webAnimation}
          loop={true}
          style={{ width: '60%', height: '100%' }}
        />
      </div>
    </div>
  )
}

export default Pricing
