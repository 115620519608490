import React from 'react'

import './index.css'
import './styles/app.scss'
import Navbar from 'components/Navbar'
import { Routes, Route } from 'react-router-dom'
import Home from 'components/pages/HomePage/Home'
import Products from 'components/pages/Products/Products'
import Pricing from 'components/pages/Pricing/Pricing'
import Services from 'components/pages/Services/Services'
import Footer from 'components/pages/Footer/Footer'

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/services" element={<Services />} />
      </Routes>
      <Footer />
    </>
  )
}

export default App
