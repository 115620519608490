import React from 'react'

import Header from './Header'
import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'
import SectionThree from './SectionThree'
import SectionTitle from './SectionTitle'
import Accordion from './Accordion'
import HomeProducts from './HomeProducts'

const Home = () => {
  return (
    <div>
      <Header />
      <SectionTitle />
      <HomeProducts />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <Accordion />
    </div>
  )
}

export default Home
