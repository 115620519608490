import React from 'react'
import İmages from 'images/px2.jpg'

import Title from 'components/ui/Title'
import Text from 'components/ui/Text'
import TextContent from 'components/ui/TextContent'
import SubTitle from 'components/ui/SubTitle'
import { Button } from 'components/ui/Button'
import './section-one.scss'

const SectionOne = () => {
  return (
    <section className="w-full h-3/6 container mx-auto section-one grid items-center gap-5  lg:grid-cols-2  ">
      <div>
        <img src={İmages} className="section-images" alt="" />
      </div>
      <div>
        <TextContent>
          <SubTitle>Profesyonel E-posta</SubTitle>
          <Title>Başarınızı ileriye taşıyın. </Title>
          <Text>
            Herkesin e-postası vardır, ancak her e-posta aynı değildir.
            Müşteriler siz@NeueBloom.com gibi bir profesyonel e-posta adresi
            olan bir işletmeyi dokuz kat daha fazla seçme eğilimi gösterir.
          </Text>
          <Button>E-posta Planlarına Bakın</Button>
        </TextContent>
      </div>
    </section>
  )
}

export default SectionOne
