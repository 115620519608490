import React from 'react'
import Lottie from 'lottie-react'
import webAnimation from 'lotties/comingsoon.json'

export const ComingSoon = () => {
  return (
    <div className="w-full h-screen">
      {' '}
      <Lottie
        animationData={webAnimation}
        loop={true}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  )
}
