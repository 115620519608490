import React from 'react'
import './text.scss'
const COLOR = ['red', 'secondary', 'brand-primary']
export default function Text({ children, color }) {
  const checkTitleColor = COLOR.includes(color) ? color : null
  return (
    <div>
      <h2 className={`text ${checkTitleColor} font-sm font-semibold mb-3 `}>
        {children}
      </h2>
    </div>
  )
}
