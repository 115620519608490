import React from 'react'
import İmages from 'images/pc1.jpg'

import Title from 'components/ui/Title'
import Text from 'components/ui/Text'
import TextContent from 'components/ui/TextContent'
import SubTitle from 'components/ui/SubTitle'
import { Button } from 'components/ui/Button'
import './section-one.scss'

const SectionOne = () => {
  return (
    <section className=" w-full container mx-auto section-one grid  gap-5  lg:grid-cols-2 my-10 ">
      <div className="flex items-center">
        <TextContent>
          <SubTitle>Alan Adları </SubTitle>
          <Title>Fikirlerinizi online ortamda büyütün. </Title>
          <Text>
            isterseniz .com ile klasiklerden şaşmayın ya da .app veya .guru ile
            yaratıcılığınızı konuşturun. İşletmeniz ve fikriniz için kusursuz
            online yuvayı bulabileceğiniz daha iyi bir yer yok.
          </Text>
          <Button>Alan adınızı bulun</Button>
        </TextContent>
      </div>
      <div>
        <img src={İmages} className="section-images" alt="" />
      </div>
    </section>
  )
}

export default SectionOne
