import React from 'react'
import './subtitle.scss'
const COLOR = ['primary', 'secondary', 'brand-primary']
export default function SubTitle({ children, color }) {
  const checkTitleColor = COLOR.includes(color) ? color : null
  return (
    <div>
      <h1
        className={`sub-title ${checkTitleColor} font-sm font-semibold mb-3 flex items-center gap-2 `}
      >
        {children}
      </h1>
    </div>
  )
}
