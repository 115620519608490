import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import SubTitle from 'components/ui/SubTitle'
import Title from 'components/ui/Title'
import Text from 'components/ui/Text'

export default function SimpleAccordion() {
  return (
    <div className="bg-radient py-20">
      <div className="container  ">
        <Title color="secondary">Sıkça Sorulan Sorular</Title>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <SubTitle>Nasıl Başlarım </SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              Web Sitesi Şablonları arasından birini seçin. Daha sonra seçmiş
              olduğunuz temanın ürün kodunu bizimle paylaşın.
            </Text>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <SubTitle> Sipariş Oluşturma </SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              Sipariş oluşturmak için ürün kodunu ve sipariş tutarının %50'sini
              ödemeniz gerekmektedir. Kalan tutar ödeme sipariş sonunda tahsil
              edilir.
            </Text>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <SubTitle> İçeriklerin Hazırlanması </SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              Firmanıza ait logo, slogan, ürünler, hizmetler, çalışanlar vs.
              Sitenize eklenecek içerikleri hazırlayıp ekibimize teslim etmeniz
              gerekmektedir. Teslim süresinden 1 gün sonra ekibimiz web sitesini
              sizlere göre özelleştirecektir.
            </Text>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <SubTitle> Revize ve Onay </SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              Belirtmiş olduğunuz revizeler var ise siteniz tekrardan düzenlenir
              ve onayınıza sunulur. Ardından Siteniz Hazır ! Hemen Yayınlanır.
            </Text>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <SubTitle> Teknik Destek </SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              Satın almış olduğunuz hizmet ve paketlere göre satış sonrası
              teknik desteğimiz ile belirtilen zaman dilimlerinde teknik destek
              hizmeti sağlamaktayız.
            </Text>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <SubTitle> Güncelleme & Revizyon </SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              Satın almış olduğunuz hizmet ve paketlere göre web sitenizi
              istediğiniz zaman güncelleyebilir , değişiklik ve revizeler
              yaptırabilirsiniz.
            </Text>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}
