import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { FaBars, FaTimes, FaInstagram, FaTwitter } from 'react-icons/fa'
import './navbar.scss'

import { IconContext } from 'react-icons/lib'
import Logo from '../images/logo.svg'

function Navbar() {
  const [click, setClick] = useState(false)
  const [button, setButton] = useState(true)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false)
    } else {
      setButton(true)
    }
  }

  useEffect(() => {
    showButton()
  }, [])

  window.addEventListener('resize', showButton)

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className="navbar shadow-sm ">
          <div className="container mx-auto">
            {' '}
            <div className="w-full flex items-center justify-between">
              <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                <img src={Logo} alt="logo" />
              </Link>
              <div className="menu-icon" onClick={handleClick}>
                {click ? (
                  <FaTimes color="#08a9a9" />
                ) : (
                  <FaBars color="#08a9a9" />
                )}
              </div>
              <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className="nav-item">
                  <Link
                    to="/products"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    Web Şablonları
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/pricing"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    Paketler
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/services"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    Teknik Destek
                  </Link>
                </li>
                <div className="flex">
                  <li className="nav-item">
                    <Link
                      to="/products"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      <FaInstagram color="7158e2" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/products"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      <FaTwitter color="#7158e2" />
                    </Link>
                  </li>
                </div>
                {/* <li className="nav-btn">
                {button ? (
                  <Link to="/sign-up" className="btn-link">
                    <Button buttonStyle="btn--outline">SIGN UP</Button>
                  </Link>
                ) : (
                  <Link
                    to="/sign-up"
                    className="btn-link"
                    onClick={closeMobileMenu}
                  >
                    <Button buttonStyle="btn--outline" buttonSize="btn--mobile">
                      SIGN UP
                    </Button>
                  </Link>
                )}
              </li> */}
              </ul>
            </div>
          </div>
        </div>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
