import React from 'react'
import 'styles/app.scss'
import './hero.scss'
import Lottie from 'lottie-react'

import webAnimation from 'lotties/lottie7.json'
import Title from 'components/ui/Title'
import Text from 'components/ui/Text'
import { Button } from 'components/ui/Button'
import SubTitle from 'components/ui/SubTitle'

const Hero = () => {
  const optionswebAnimation = {
    animationData: webAnimation,
    loop: true,
  }
  return (
    <div className="hero container  mx-auto grid lg:grid-cols-2  ">
      <div className="  grid lg:grid-cols-2 gap-5">
        <div className="hero_content sm:gap-2  ">
          <Title>Hayalinizdeki Web Sitesini Kurma Özgürlüğü</Title>
          <SubTitle>
            Profesyonelce Tasarlanmış Hazır Web Sitesi Şablonları
          </SubTitle>
          {/* <Text>Bir web sitesinden fazlasını elde edin.</Text> */}
          <Text>
            İşinizin ihtiyaçlarını karşılayacak şekilde tasarlanmış 500'ü aşkın
            özelleştirilebilir hazır web sitesi şablonu arasından seçim yapın.{' '}
            <span className="font-medium">
              temaatolyesi ekibi sizler için hazırlasın
            </span>
          </Text>
          <div className="flex   gap-5">
            <Button buttonStyle="btn--primary" buttonSize="btn--medium">
              Detaylı Bilgi{' '}
            </Button>
            <Button buttonStyle="btn--secondary" buttonSize="btn--medium">
              Şablonlar{' '}
            </Button>
          </div>
        </div>
        <div className="hero_images  ">
          <Lottie
            animationData={webAnimation}
            loop={true}
            style={{ width: '100%', height: '100%' }}
          />
          {/* <video autoPlay loop muted>
            <source src={HeroVideo} type="video/mp4" />
          </video> */}
        </div>
      </div>
    </div>
  )
}

export default Hero
