import React from 'react'
import SubTitle from 'components/ui/SubTitle'
import Title from 'components/ui/Title'
import Text from 'components/ui/Text'
import { Button } from 'components/ui/Button'
import { BiRightArrow } from 'react-icons/bi'
import TeamPic from 'images/teampic1.jpg'
import './section-title.scss'
const SectionTitle = () => {
  return (
    <div className="bg-radient p-10 rounded">
      <div className="container mx-auto grid md:grid-cols-2 py-10">
        <div className="section-images   ">
          <img src={TeamPic} alt="" />
        </div>
        <div className="flex flex-col justify-between bg-bg-2    gap-2">
          {' '}
          <Title color="secondary">Neler Sağlıyoruz</Title>
          <SubTitle color="secondary">
            {' '}
            Web Sitesi{' '}
            <span className="underline ">
              Maliyetlerini Ortadan Kaldırıyoruz !
            </span>
          </SubTitle>
          <SubTitle color="secondary">
            {' '}
            Seçtiğiniz Web Sitesi Şablonlarını size göre özelleştiriyoruz.
          </SubTitle>
          <Text color="secondary">
            İhtiyacınız olan web sitesini yüksek maliyetler ile tasarlamak ve
            hayata geçirmek yerine temaatolyesinde bulunan web şablonlarınından
            birini seçerek hızlı ve çok ucuza sahip olabilirsiniz.
          </Text>
          <Button buttonStyle="btn--secondary">Hemen Başla</Button>
        </div>
      </div>
    </div>
  )
}

export default SectionTitle
