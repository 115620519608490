import { ComingSoon } from 'components/ui/ComingSoon'
import React from 'react'

const Products = () => {
  return (
    <div className="container mx-auto">
      <ComingSoon />
    </div>
  )
}

export default Products
