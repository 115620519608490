import React from 'react'
import './header.scss'
import Hero from './Hero'

const Header = () => {
  return (
    <header>
      <div className="header   ">
        <Hero />
      </div>
    </header>
  )
}

export default Header
