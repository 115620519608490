import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Button } from 'components/ui/Button'
import { BsEyeFill } from 'react-icons/bs'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import './home-products.scss'
import 'swiper/scss/navigation'
import 'swiper/scss/parallax'
// import required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Parallax,
} from 'swiper'

import SubTitle from 'components/ui/SubTitle'
import Text from 'components/ui/Text'
import productData from 'data/homeProductData.json'

export default function HomeProducts() {
  const [homeProducts, setHomeProducts] = useState([])

  useEffect(() => {
    setHomeProducts(productData)
  }, [])

  return (
    <div className=" container mx-auto m-5 p-12 relative ">
      <div>
        <Swiper
          breakpoints={{
            // when window width is >= 640px
            640: {
              width: 640,
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 2,
            },
          }}
          spaceBetween={50}
          autoplay={true}
          // pagination={{ clickable: true }}

          modules={[
            Navigation,
            Pagination,
            Scrollbar,
            A11y,
            Autoplay,
            Parallax,
          ]}
          className="mySwiper"
        >
          {homeProducts.map((product, index) => (
            <SwiperSlide key={index}>
              <div className="w-90 border shadow-xl p-3 rounded-md">
                <img
                  src={product.image}
                  alt={product.product_name}
                  // style={{ width: '500px', height: '300px' }}
                  className="card-images"
                />
                <div className="my-5 border-b border-gray-300 ">
                  {' '}
                  <SubTitle>{product.product_name}</SubTitle>
                </div>
                <div className="mt-2  text-justify  ">
                  {' '}
                  <Text>{product.text}</Text>
                </div>
                <div className="mt-2 flex gap-2  flex-wrap   ">
                  {product.technology.map((data) => {
                    return (
                      <div className="  rounded p-1 px-4 border-2  text-sm text-gray-400 ">
                        {' '}
                        {data.name}
                      </div>
                    )
                  })}
                </div>

                <div className="my-4  ">
                  <Button buttonStyle="btn--primary" buttonSize="btn--mobile">
                    <BsEyeFill size="24px" />
                    <span className="ml-2">İncele</span>
                  </Button>
                  {/* <div className="flex  gap-1   ">
                    <Text>{product.price}</Text>
                    <Text>{product.currency}</Text>
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
