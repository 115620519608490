import React from 'react'
import İmages from 'images/pc1.jpg'

import Title from 'components/ui/Title'
import Text from 'components/ui/Text'
import TextContent from 'components/ui/TextContent'
import SubTitle from 'components/ui/SubTitle'
import { Button } from 'components/ui/Button'
import './section-one.scss'

const SectionOne = () => {
  return (
    <section className="w-full  container mx-auto section-one grid items-center gap-5  lg:grid-cols-2 my-10 ">
      <div>
        <TextContent>
          <SubTitle>SSL Güvenliği</SubTitle>
          <Title>Verilerinizi ve müşterilerinizi koruyun. </Title>
          <Text>
            SSL Sertifikası, kişisel bilgilerden kredi kartı numaralarına kadar
            sitenize giden ve sitenizden gelen verileri korur. Ziyaretçiler
            güvende hissettiğinde işlerini size verme ihtimalleri artar.
          </Text>
          <Button>Detaylı Bilgi</Button>
        </TextContent>
      </div>
      <div>
        <img src={İmages} className="section-images" alt="" />
      </div>
    </section>
  )
}

export default SectionOne
